<template>
    <div class="page-wrap-">
        <v-row style="min-height: 100vh">
            <v-col cols="0" md="2" class="ma-0 pa-0 text-center " align-self="center">
            </v-col>
            <v-col cols="12" md="4" class="ma-0 pa-5 text-center " align-self="center">
                <h3 style="margin-bottom: -20px">{{ $t("Signin.Welcome") }}</h3>                
                <img src="@/assets/images/massage-logo-w.png" alt="" class="mb-0 pb-0" /> 
                <!-- <img src="@/assets/images/flags/1x1/gr.svg" alt="" class="mb-0 pb-0" />  -->
            </v-col>
            <v-col cols="12" md="4" class="ma-0 pa-0" align-self="center">
                <div class="session-form-hold align-self-stretch">
                    <base-card>
                        <v-progress-linear
                            :active="loading"
                            :indeterminate="loading"
                            absolute
                            top
                            color="primary"
                        ></v-progress-linear>
                        <v-card-text class="text-center pb-10">
                
                            <!-- <img src="@/assets/images/massage-logo-w.png" alt="" />                     -->
                            
                            <div class="d-flex justify-space-between">                    
                                <h6 class="text--disabled- font-weight-medium mb-2">
                                    {{ $t("Signin.Sign in to your account") }}
                                </h6>
                                <LanguageSelector></LanguageSelector>
                                <!-- <div>
                                    <span>Ελληνικά</span>
                                    <v-avatar size="20px" class="ml-2">
                                        <v-img src="@/assets/images/flags/1x1/gr.svg" alt />
                                    </v-avatar>                                
                                </div>   -->
                                <!-- <v-menu left bottom>
                                    <template v-slot:activator="{on}">
                                        <v-btn v-on="on" text small class="text-capitalize">
                                            <span class="text--disabled">Lang:</span>
                                            <v-avatar size="20px" class="ml-2">
                                                <v-img src="@/assets/images/flags/1x1/gb.svg" alt />
                                            </v-avatar>                                
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item  @click="() => {}">
                                            <v-list-item-title>
                                                <v-avatar size="14px" class="mr-2">
                                                    <v-img src="@/assets/images/flags/1x1/gr.svg" alt />
                                                </v-avatar>      
                                                <span class="text--disabled" style="line-height: 25px">Ελληνικά</span>                                                
                                            </v-list-item-title>                                            
                                        </v-list-item>
                                        <v-list-item @click="() => {}">
                                            <v-list-item-title>
                                                <v-avatar size="14px" class="mr-2">
                                                    <v-img src="@/assets/images/flags/1x1/gb.svg" alt />
                                                </v-avatar>      
                                                <span class="text--disabled" style="line-height: 25px">English</span>                                                
                                            </v-list-item-title>                                            
                                        </v-list-item>                                       
                                    </v-list>
                                </v-menu> -->
                                                               
                            </div>                
                            <v-form class="pt-10">
                                <!-- <v-text-field
                                    label="email"
                                    v-model="email"
                                    :rules="emailRules"
                                    required
                                    :validate-on-blur="true"
                                />
                                <v-text-field
                                    label="email"
                                    v-model="email"
                                    :rules="emailRules"
                                    required
                                    :validate-on-blur="true"
                                />
                                <v-text-field
                                    label="email"
                                    v-model="email"
                                    :rules="emailRules"
                                    required
                                    :validate-on-blur="true"
                                />
                                <v-text-field
                                    label="email"
                                    v-model="email"
                                    :rules="emailRules"
                                    required
                                    :validate-on-blur="true"
                                />
                                <v-text-field
                                    label="email"
                                    v-model="email"
                                    :rules="emailRules"
                                    required
                                    :validate-on-blur="true"
                                />-->
                                <!-- <v-text-field
                                    label="email"
                                    v-model="email"
                                    :rules="emailRules"
                                    required
                                    :validate-on-blur="true"
                                />  -->
                                <v-text-field
                                    ref="username"
                                    label="E-mail"
                                    v-model="email"                                    
                                    :error-messages="usernameValstate"
                                    required
                                    :rules="nameRules"
                                    :validate-on-blur="true"
                                />
                                <!-- <v-text-field
                                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show ? 'text' : 'password'"
                                    name="input-10-2"
                                    label="Password"                            
                                    :rules="nameRules"
                                    v-model="ePassword"
                                    @click:append="show = !show"
                                    :validate-on-blur="true"
                                ></v-text-field> -->
                                <v-text-field
                                    ref="password"
                                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show ? 'text' : 'password'"
                                    name="input-10-2"
                                    label="Password"                                                                
                                    v-model="ePassword"
                                    @click:append="show = !show"     
                                    required           
                                    :error-messages="passwordValstate" 
                                    :rules="nameRules"
                                    :validate-on-blur="true"         
                                ></v-text-field>
                                <v-btn
                                    class="mb-4 mt-10 text-capitalize"
                                    @click="formSubmit"
                                    block
                                    color="#EB8E45"
                                    dark
                                >
                                    <v-icon left>mdi-login</v-icon>
                                    {{ $t("Signin.LOG IN") }}</v-btn
                                >                                
                                <v-alert dense outlined type="error" v-if="!(generalValstateValid)">
                                    {{ generalValstate }}
                                </v-alert>
                                <div class="d-flex justify-around- flex-wrap- justify-space-between">
                                     <v-btn
                                        class="mb-4 text-capitalize"                                                                                
                                        color="#EA4335"
                                        dark                                        
                                        @click="googleLogin"
                                    >
                                        <v-icon left>mdi-google</v-icon>
                                        Google</v-btn
                                    >
                                    <v-btn
                                        class="mb-4 text-capitalize"                                                                                
                                        color="#1877F2"
                                        dark                                        
                                        @click="facebookLogin"
                                    >
                                        <v-icon left>mdi-facebook</v-icon>
                                        Facebook</v-btn
                                    >   
                                    <v-btn
                                        class="mb-4 text-capitalize"                                                                                
                                        color="black"
                                        dark                           
                                        @click="appleLogin"              
                                    >
                                        <v-icon left>mdi-apple</v-icon>
                                        Apple</v-btn
                                    > 
                                </div>
                                <!-- <div class="d-flex justify-around- flex-wrap- justify-space-between-">
                                    <v-btn
                                        class="mb-4 text-capitalize d-flex mr-2"                                                                                
                                        color="#EA4335"
                                        dark
                                        style="flex: 1"
                                        @click="googleLogin"
                                    >
                                        <v-icon left>mdi-google</v-icon>
                                        Google</v-btn
                                    > 
                                    <v-btn
                                        class="mb-4 text-capitalize d-flex ml-2"                                                                                
                                        color="#1877F2"
                                        dark
                                        style="flex: 1"
                                        @click="facebookLogin"
                                    >
                                        <v-icon left>mdi-facebook</v-icon>
                                        Facebook</v-btn
                                    >                                     
                                </div>
                                <div class="d-flex justify-around- flex-wrap- justify-space-between-">
                                    <v-btn
                                        class="mb-4 text-capitalize d-flex mr-2"                                                                                
                                        color="black"
                                        dark
                                        style="flex: 1"
                                    >
                                        <v-icon left>mdi-apple</v-icon>
                                        Apple</v-btn
                                    > 
                                    <v-btn
                                        class="mb-4 text-capitalize d-flex ml-2"                                                                                
                                        color="#1877F2"
                                        dark
                                        style="flex: 1"
                                    >
                                        <v-icon left>mdi-instagram</v-icon>
                                        Instagram</v-btn
                                    >                                     
                                </div> -->
                                <div class="d-flex ">
                                    <v-btn text small block color="black" class="mb-2 text-capitalize"
                                    to="/auth/forgotpass"
                                        >{{ $t("Signin.Forgot Password") }}</v-btn
                                    >
                                </div>
                                <div class="d-flex  justify-space-between- text-center">
                                    <v-btn
                                        class="text-capitalize"
                                        block
                                        text
                                        small
                                        color="black"
                                        to="/auth/signup"
                                        >{{ $t("Signin.Create New Account") }}</v-btn
                                    >
                                </div>
                            </v-form>
                        </v-card-text>
                    </base-card>
                </div>
            </v-col>
            <v-col cols="0" md="2" class="ma-0 pa-0 text-center " align-self="center">
            </v-col>
        </v-row>        
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex';
import api from "src/api/index";
import LanguageSelector from "@/components/core/LanguageSelector";
export default {
    name: 'login',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'SignIn'
    },
    components: {
        LanguageSelector
    },
    data() {
        return {
            show: false,
            password: '',
            checkbox1: true,
            checkbox2: false,
            email: '',
            ePassword: '',            
            loading: false,
            emailRules: [
                (v) => !!v || 'E-mail is required',
                (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            ],
            nameRules: [
                (v) => !!v || this.$root.$i18n.t("validation.fieldisrequired"),
               
            ]
        }
    },
    computed: {
        ...mapGetters(['loggedInUser', 'error', 'selectedLanguage']),
        usernameValstate() {            
            // //let err = this.error;
            // let err = this.$state.getters.error;
            // if(err.username) {
            //     if(err.username.msg) {
            //         return err.username.msg;
            //     }
            // }
            // return "ttttt";
            // //return Date.now().toString();
            return this.error?.username?.msg;
        },
        passwordValstate() {
            return this.error?.password?.msg;
        },
        generalValstate() {
            return this.error?.general?.msg;
        },
        generalValstateValid() {
            let r = this.error?.general?.valid;
            if(typeof r === 'undefined') {
                return true;
            }
            else {
                return r;
            }            
            // console.log("generalValstateValid: " + r);
            // return this.error?.general?.valid;
        }
    },
    methods: {
        ...mapActions(['login']),
        formSubmit() {     
            //this.error = '';       
            this.$refs.username.validate(true);
            this.$refs.password.validate(true);
            if(this.$refs.username.valid && this.$refs.password.valid) {
                this.login({username: this.email, password: this.ePassword, lang: this.selectedLanguage})            
            }
            else {
                console.log("invalid fields found2");
            }            
        },        
        googleLogin() {
            //this.$router.push('/api/auth/google');
            window.location.href = "/api/auth/google";
        },
        facebookLogin() {
            //this.$router.push('/api/auth/google');
            window.location.href = "/api/auth/facebook";
        },
        appleLogin() {
            //this.$router.push('/api/auth/apple');
            window.location.href = "/api/auth/apple";
        }
    },
    watch: {
        // loading (val) {
        //   if (!val) return
        //   setTimeout(() => (this.loading = false), 2000)
        // },
        loggedInUser(val) {
            if (val && val.token && val.token.length > 0) {
                // this.makeToast("success", "Successfully Logged In");
                console.log('logged in successfully ')
                this.loading = true
                setTimeout(() => {
                    this.$router.push('/')
                }, 500)
            }
        },
        error(val) {            
            if (val != null) {
                let test = "tete";
                //this.error = val.message;                
            }
            return val;
        }
    }
}
</script>
<style lang="scss" scoped>
.page-wrap {
    //background-color: #242939 !important;
    background-color: #f2f4f4 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
